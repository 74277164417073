export default {
    trackerTypes: {
        'private': 'Private',
        'public': 'Public',
        'list': 'List',
    },

    trackerStatus: {
        'draft': 'Draft',
        //'psc_required': 'PSC required',
        //'review': 'Review',
        //'approved': 'Approved',
        //'rejected': 'Rejected',
        'live': 'Live',
        'closed': 'Closed',
        'award': 'Award',
        'archive': 'Archive',
        //'switch_pending': 'Switch pending',
        //'switch_approved': 'Switch approved',
        //'switch_rejected': 'Switch rejected',
    },

    tasksStatus: {
        'open': 'Open',
        'in_progress': 'In progress',
        'blocked': 'Blocked',
        'done': 'Done',
    },

    customFieldAccessTypes: {
        'read-only': 'Read Only',
        'edit': 'Edit',
    },

    interactionTypes: {
        phone_call: 'Phone Call',
        email: 'Email',
        meeting: 'Meeting',
    },

    ecosystemTypes: {
        'incubator': 'Incubator',
        'accelerator': 'Accelerator',
        'university': 'University',
        'event': 'Event',
        'venture-capitalist': 'Venture Capitalist',
        'association': 'Association',
    },

    customContactDetailTypes: {
        'phone': 'Phone',
        'email': 'Email',
        'social': 'Social',
    },

    fundingTypes: {
        'Angel': 'Angel',
        'Crowdfunding': 'Crowdfunding',
        'Seed': 'Seed',
        'Series A': 'Series A',
        'Series B': 'Series B',
        'Series C': 'Series C',
        'Series D': 'Series D',
        'Series E': 'Series E',
        'Series F': 'Series F',
        'Series G': 'Series G',
        'Series H': 'Series H',
        'Series I': 'Series I',
        'Series J': 'Series J',
        'Unknown': 'Unknown',
        'Venture - Unspecified': 'Venture - Unspecified',
    },

    fundingTypesForEdit: {
        'Angel': 'Angel',
        'Seed': 'Seed',
        'Crowdfunding': 'Crowdfunding',
        'Series A': 'Series A',
        'Series B': 'Series B',
        'Series C': 'Series C',
        'Series D': 'Series D',
        'Series E': 'Series E',
        'Series F': 'Series F',
        'Series G': 'Series G',
        'Series H': 'Series H',
        'Series I': 'Series I',
        'Series J': 'Series J',
    },

    ecosystemStatuses: {
        'unmapped': 'Unmapped',
        'mapped': 'Mapped',
        'crawled': 'Crawled',
        'rejected': 'Rejected',
    },

    ecosystemApproveStatuses: {
        'in_review': 'In review',
        'approved': 'Approved',
        'rejected': 'Rejected',
        'private': 'Private',
    },

    groupRolesNames: {
        'member': 'Member',
        'admin': 'Admin',
    },

    ecosystemAccountTypes: {
        'free': 'Free',
        'invited': 'Invited',
        'standard': 'Standard',
        // 'basic': 'Basic',
        'premium': 'Premium',
        'trial': 'Trial',
    },

    ecosystemAccountTypesPrices: {
        // 'basic': '$10K annually',
        'premium': '$15k annually',
    },

    ecosystemLimits: {
        'users': {
            'free': 1,
            'invited': 2,
            // 'basic': 3,
            'standard': 5,
            'premium': 10,
        },
        'startups-add': {
            'free': 250,
            'invited': 250,
            'trial': 250,
            'standard': 500,
            // 'basic': 250,
        },
        'startups-invite': {
            'standard': 500,
            // 'basic': 250,
        },
        'enterprises-invite': {
            'standard': 10,
            // 'basic': 3,
        },
        'enterprises-connect': {
            // 'basic': 1,
            'standard' : 10
        },
        'enterprises-connect-request': {
            'free': 1,
            'invited': 1,
            // 'basic': 10,
            'standard': 20,
        },
    },

    ecosystemAccountTypesFeatures: {
        'free': [
            'No ability to connect to enterprises',
            'No ability to invite startups',
            '1 user per account',
        ],
        'invited': [
            '1 enterprise connection',
            'No ability to invite startups',
            '2 users per account',
        ],
        /* Eliminated Basic access
        'basic': [
            '1 connect out of hundreds of enterprises',
            '3 invitations to your own enterprise relationships',
            'Up to 250 startups in portfolio',
            '3 users per account',
        ],
       */
        'standard': [
            'Access to hundreds of enterprises',
            'Up to ten enterprise connections',
            'Full portfolio management',
            'Up to five users per account',
            // 'Startup progress reports on homepage',
            'Consult expert SwitchPitch analysts for dealmaking',
            // 'Prioritized startup placement in search results',
        ],
        'premium': [
            'Unlimited connects to enterprises',
            'Search over 450,000 startups',
            'Up to ten users per account',
            //'Gain insight into trends on SwitchPitch and across the web',
            // 'Insights page',
        ],
        'trial': [
            'Search over 450,000 startups',
            'Up to ten users per account',
            //'Gain insight into trends on SwitchPitch and across the web'
        ],
    },

    enterpriseAccountTypes: {
        'free': 'Free',
        'premium': 'Premium',
        'test': 'Test',
        'trial': 'Trial',
        'pilot': 'Pilot',
        'event_limited': 'Free',
    },

    enterpriseLimits: {
        'trackers': {
            'free': 3,
        },
        'ecosystems-invite': {
            'free': 3,
        },
    },

    enterpriseAccountTypesPrices: {
        // 'premium': '$50K annually',
    },

    enterpriseAccountTypesFeatures: {
        free: [
            'Accept connection invites from ecosystems',
            'View startups added by connected ecosystems',
            'Invite up to 3 ecosystems',
            'Create up to 3 trackers',
            'Max 2 users',
        ],

        event_limited: [
            '',
            "Access only {ecosystem}'s attendees",
            '',
            '',
            'Max 1 user',
            '',
            '',
            '',
            '',
            '',
            "Message only {ecosystem}'s attendees",
        ],

        premium: [
            'Connect with unlimited ecosystems',
            'Search and interact with over 450,000 startups',
            'Access to Innovation Readiness Level Score',
            'Create unlimited trackers',
            'Unlimited users & permissioning',
            'View company & startup activity',
            'Create and assign tasks to team members',
            'Add and invite startups to platform'
        ],

        premiumTeaser: {
            common: [
                'Connect with unlimited ecosystems',
                'Search and interact with over 450,000 startups',
                'Access to Innovation Readiness Level Score',
                'Create unlimited trackers',
                'Unlimited users & permissioning',
                'View company & startup activity',
                'Create and assign tasks to team members',
                'Add and invite startups to platform'
            ],

            event_limited: [
                '',
                'Search and interact with over 450,000 startups',
                '',
                '',
                'Unlimited users & permissioning',
                '',
                '',
                '',
                'Generative AI search',
                'Reporting',
                "Unlimited messaging",
                'Analyst hours',
                'Custom research reports'
            ]
        },
    },

    contactSources: {
        '': '',
        'cb': 'Crunchbase',
        'kg': 'Diffbot',
        'user': 'Real User',
    },

    homepageSections: {
        'dashboard' : 'Dashboard',
        'tasks' : 'Tasks',
        'startups' : 'Startups',
        'searches' : 'Searches',
        'trackers' : 'Trackers',
        'users' : 'Users',
        'categories' : 'Top Funded Categories',
        'trending' : 'Trending',
    },

    commonFilterPeriods: [
        {
            value: "30",
            label: 'Last 30 days'
        },
        {
            value: "60",
            label: 'Last 60 days'
        },
        {
            value: "90",
            label: 'Last 90 days'
        },
        {
            value: 'quarter',
            label: 'This quarter'
        },
        {
            value: 'year',
            label: 'This year'
        },
        {
            value: 'all',
            label: 'All time'
        },
    ],

    usersTypes: [
        {
            value: 'all',
            label: 'All users'
        },
        {
            value: 'groups',
            label: 'My groups'
        },
        {
            value: 'my',
            label: 'Only me'
        },
    ],

    spinnerColor: '#0083b2',

    companyPfizer: 189702,
    companyAnton: 112572,
    companyColgate: 229593,
    companyTravelers: 290444,
    companyChickFilA: 61319,
    companyABI: 211260,
    // companyNeom: 112572,
    companyNeom: 290466,
    companyLloyds: 290569,
    companyCollins: 290532, //112572,
    companyCoPace: 291505, // 291505 on prod // 291482 on dev
};

export const activityFeedTypes = {
    'today': 'Today',
    'yesterday': 'Yesterday',
    'week': 'Previous 7 Days',
    'month': 'This Month',
}

export const interactionTypes = {
    'phone_call': 'Phone Call',
    'email': 'Email',
    'meeting': 'Meeting',
    'demo': 'Demo',
}

export const monthsList = [
    'Jan',
    'Feb',
    'Mar',
    'Apr',
    'May',
    'Jun',
    'Jul',
    'Aug',
    'Sep',
    'Oct',
    'Nov',
    'Dec',
]

export const inviteStatuses = {
    'all': 'Showing All',
    'active': 'Active',
    'invite_sent': 'Invite Sent',
    'invite': 'Invite',
}
